import { useEffect } from 'react';
import { useRouter } from 'next/router';
// routes
import { PATH_LOGIN } from '../routes/paths';

export default function Index() {
  const router = useRouter();

  useEffect(() => {
    if (router.pathname == '/') {
      router.push({
        pathname: PATH_LOGIN,
        query: router.query,
      });
    }
  });

  return null;
}
