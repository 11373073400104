
function path(root, sublink) {
  return `${root}${sublink}`;
}

const ROOTS_DASHBOARD = '/dashboard';
const ROOTS_USER = '/user';
const ROOTS_CONTRACT = '/contract';
const ROOTS_TRANSFER = '/transfer';
const ROOTS_MATCH = '/match';
const ROOTS_WORK = '/work';
const ROOTS_FLIERS = '/fliers';
const ROOTS_AI = '/ai';
const ROOTS_SCOUT = '/scout';
const ROOTS_SOURCING = '/sourcing';

export const PATH_DASHBOARD = {
  root: ROOTS_DASHBOARD,
};

export const PATH_USER = {
  root: ROOTS_USER,
  detail: path(ROOTS_USER, '/detail'),
}

export const PATH_PAGE = {
  page404: '/404',
  page500: '/500',
};

export const PATH_CONTRACT = {
  root: ROOTS_CONTRACT,
  list: path(ROOTS_CONTRACT, '/list'),
  renewal: path(ROOTS_CONTRACT, '/renewal'),
}

export const PATH_TRANSFER = {
  root: ROOTS_TRANSFER,
}

export const PATH_LOGIN = '/login';

export const PATH_MATCH = {
  root: ROOTS_MATCH,
};

export const PATH_WORK = {
  root: ROOTS_WORK,
};

export const PATH_FLIERS = {
  root: ROOTS_FLIERS,
};

export const PATH_AI = {
  root: ROOTS_AI,
};

export const PATH_SCOUT = {
  root: ROOTS_SCOUT,
};

export const PATH_SOURCING = {
  root: ROOTS_SOURCING,
};
